@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap');

* {
    font-family: "Syne", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

/* CONTAINERS */
.container-1000 {
    @apply min-[1000px]:w-[1000px] w-full mx-auto min-[1000px]:px-0 px-5
}

.container-1160 {
    @apply min-[1160px]:w-[1160px] w-full mx-auto min-[1160px]:px-0 px-5
}

.container-1200 {
    @apply min-[1200px]:w-[1200px] w-full mx-auto min-[1200px]:px-0 px-5
}

.container-1440 {
    @apply min-[1440px]:w-[1440px] w-full mx-auto
}

.container-2000 {
    @apply min-[3000px]:w-[2000px] mx-auto
}


/* COUSTOM COLORS */
.bg-blue {
    background-color: #1897FC;
}

.bg-blue1 {
    background-color: #060012;
}

.text-blue1 {
    color: #000D4B;
}

.text-blue2 {
    color: #1A1B60;
}

.text-blue3 {
    color: #1897FC;
}

.text-gray1 {
    color: #667280;
}

.text-gray2 {
    color: #939EAA;
}

/* COUSTOM TITLES */
.title1 {
    @apply lg:text-[96px] md:text-[60px] text-[30px] font-bold lg:leading-[100px]
}

.title2 {
    @apply lg:text-[64px] md:text-[55px] sm:text-[30px] text-[25px] font-bold leading-none;
}

/* COUSTOM BUTTON STYLES */
.btn-primary {
    @apply px-6 sm:py-3 py-2 rounded-full text-[16px] text-white font-medium;
}


/* Coustom scrollBars */
.scroll-none::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.scroll-5px::-webkit-scrollbar {
    width: 5px;
    height: 15px;
    background-color: rgba(255, 255, 255, 0.080);
    border-radius: 10px;
}

.scroll-5px::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.200);
    border-radius: 10px;
}

.bordered-text {
    font-family: 'Syne', sans-serif;
    color: transparent;
    -webkit-text-stroke: 1px white;
    /* Create a white border around the text */
    text-align: left;
}



/* Animations */


/* grow-element */
.grow-element {
    transform: scale(1);
    transition: transform 0.2s ease-in-out;
}

.grow-element:hover {
    transform: scale(1.03);
}

/* tracking-in-expand  */
@keyframes tracking-in-expand {
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }

    40% {
        opacity: 0.6;
    }
}

.tracking-in-expand {
    animation: tracking-in-expand 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}


/* rotate-element  */
@keyframes rotate {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.rotate-element {
    animation: rotate 4s linear infinite;
}

/* reverce-rotate-element  */
@keyframes rerotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.reverce-rotate-element {
    animation: rerotate 4s linear infinite;
}

/* fade-element */
@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fade-element {
    animation: fadeInOut 2s ease-in-out infinite;
}

/* pulse-element */
@keyframes pulse {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }
}

.pulse-element {
    animation: pulse 2s ease-in-out infinite;
}

/* flash-element */
@keyframes flash {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

.flash-element {
    animation: flash 1.5s linear infinite;
}

/* bounce-element */
@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }
}

.bounce-element {
    animation: bounce 1s ease-in-out infinite;
}


@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}

.typing-element {
    animation: typing 3.5s steps(40, end), blink 0.75s step-end infinite;
}